<template>
  <div class="layout-root">
    <div
      v-if="theme === 'explore'"
      class="background"
      @click="navigateToParentPage"
    />

    <TransitionGroup
      v-if="selectedPeriod.dateRange.length === 2"
      name="list"
      tag="div"
      class="container"
    >
      <div
        key="main"
        class="main"
      >
        <div
          v-if="!isEditing"
          class="main__actions"
        >
          <AnalyticsWrapperSelectDateRange />
          <AnalyticsDimensionFiltersButton class="actions__dimension-filters" />
          <AnalyticsScopeToggle />
        </div>

        <div class="main__page">
          <slot />
        </div>
      </div>
    </TransitionGroup>
  </div>
</template>

<script lang="ts">
import { mapState, mapActions } from 'pinia'
import type { SlotsType } from 'vue'
import type { RouteLocation } from 'vue-router'
import { ScopeType } from '~/types/permissions'
import { useLayoutStore } from '~/stores/layout'
import { useFiltersStore } from '~/stores/filters'
import { type Filter } from '~/types/analytics'
import { useScopeStore } from '~/stores/scope'
import { useCubeStore } from '~/stores/cube'
import { getDimensionFromTitle } from '~/services/explore'
import { getDashboardFromRoute, navigateToParentPage } from '~/services/router'
import { useTimeDimensionStore } from '~/stores/timeDimension'

export default {
  name: 'DashboardLayout',
  slots: Object as SlotsType<{
    default: {}
  }>,
  setup() {
    return { navigateToParentPage }
  },
  computed: {
    ...mapState(useLayoutStore, ['theme', 'isEditing']),
    ...mapState(useFiltersStore, ['areFiltersOpen']),
    ...mapState(useTimeDimensionStore, ['selectedPeriod'])
  },
  watch: {
    $route: {
      async handler(newVal: RouteLocation, oldVal: RouteLocation | undefined) {
        const newDashboard = getDashboardFromRoute(newVal)
        const oldDashboard = oldVal ? getDashboardFromRoute(oldVal) : undefined

        // No dashboard navigation
        if (!newDashboard && !oldDashboard) {
          return
        }

        // New dashboard navigation
        if (newDashboard && newDashboard !== oldDashboard) {
          this.prepareDashboardDefaultFilters()
        }
        // Change or exit dashboard navigation
        else if (!newDashboard || newDashboard !== oldDashboard) {
          await this.cleanDefaultFilterIdsToRemove()
        }
      },
      immediate: true
    }
  },
  async created() {
    const { loadDashboardDefaultFilters } = useFiltersStore()
    await loadDashboardDefaultFilters()
    await this.generateSelectedScopeType()
    const { loadMeta } = useCubeStore()
    await loadMeta()
    await this.generateSelectedDimensionFilters()
    await useTimeDimensionStore().generateSelectedDateRange()
  },
  beforeUnmount() {
    // Resetting all the analytics stores
    useScopeStore().$reset()
    useFiltersStore().$reset()
  },
  methods: {
    ...mapActions(useFiltersStore, [
      'prepareDashboardDefaultFilters',
      'cleanDefaultFilterIdsToRemove',
      'setCustomFilters',
      'toggleFilters'
    ]),
    async generateSelectedDimensionFilters() {
      const route = this.$router.currentRoute.value
      if (!route.query.filters) {
        await this.setCustomFilters({
          values: [],
          shouldModifyUrlParams: false
        })
        return
      }

      try {
        const customFilters = JSON.parse(
          decodeURIComponent(route.query.filters as string)
        ) as Filter[]

        if (customFilters.length === 0) {
          throw new Error('bad filters')
        }

        customFilters.forEach(customFilter => {
          if (
            !('member' in customFilter) ||
            !('operator' in customFilter) ||
            !('values' in customFilter)
          ) {
            throw new Error('bad filters')
          }

          const dimension = getDimensionFromTitle(customFilter.member)

          if (!dimension) {
            throw new Error('bad filters')
          }
        })

        await this.setCustomFilters({
          values: customFilters,
          shouldModifyUrlParams: false
        })

        setTimeout(() => {
          this.toggleFilters()
        }, 500)
      } catch (error) {
        await this.setCustomFilters({
          values: [],
          shouldModifyUrlParams: true
        })
      }
    },
    async generateSelectedScopeType() {
      const route = this.$router.currentRoute.value

      const scopeType = route.query.scopeType as string
      const { setSelectedScopeType } = useScopeStore()

      if (
        !scopeType ||
        !Object.values(ScopeType).includes(scopeType as ScopeType)
      ) {
        await setSelectedScopeType()
        return
      }

      await setSelectedScopeType(scopeType as ScopeType, false)
    }
  }
}
</script>

<style lang="scss" scoped>
.layout-root {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow: auto;
}

.background {
  z-index: 2;
  position: absolute;
  height: 100%;
  width: 100%;
  background-color: $overlay-background;
  cursor: pointer;
}

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: $page-width;
}

.main {
  position: relative;
  padding: $margin-regular 40px $margin-medium;
  width: 100%;
  box-sizing: border-box;

  &__actions {
    z-index: 3;
    position: absolute;
    right: 40px;
    height: 54px;
    display: flex;
    align-items: center;
    gap: $margin-small;
  }

  &__page {
    padding: 0;
    margin-left: $side-width;
    width: calc(100% - #{$side-width});
    position: relative;
  }
}
</style>
